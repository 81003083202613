import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import StockIndexFuturesSymbol from './StockIndexFuturesSymbol';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { daddy960_stocknerve_strategyGroup } from '../daddy960_stocknerve_initStrategies';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { MdDeleteForever } from 'react-icons/md';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { stocknerve_store } from '../daddy960_stocknerve_SidePane3';
import LoadCard from '~/modules/symbolPlatform/LoadCard';
export const WatchListContent = memo(function WatchListContent(props) {
    const { state, acts } = useSymbolWatchList(props.groupName);
    const symbolListArray = state.symbolListArray;
    const storeState = useSnapshot(stocknerve_store);
    const futuresDisctionary = useSnapshot(staticStore.symbol.stockFuturesMapper);
    //現貨報價
    const indexSymbols = symbolListArray.map(s => s.includes('-1') && !s.includes('TX') && !s.includes('MTX')
        ? fr_instrument.getUnderlying(s.slice(0, 3))?.symbol ?? s
        : s);
    //期貨商品
    const futuresSymbols = indexSymbols?.map(s => futuresDisctionary?.[s] + '-1');
    //拿取報價 分別是現貨與期貨
    useEffect(() => {
        signalrStore2.addQuote(indexSymbols);
        return () => {
            signalrStore2.removeQuote(indexSymbols);
        };
    }, [JSON.stringify(indexSymbols)]);
    useEffect(() => {
        signalrStore2.addQuote(futuresSymbols);
        return () => {
            signalrStore2.removeQuote(futuresSymbols);
        };
    }, [JSON.stringify(futuresSymbols)]);
    const indexValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(indexSymbols, 1));
    const futuresValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(futuresSymbols, 1));
    const main = storeState.displayType !== 'futures' ? indexValue : futuresValue;
    const sub = storeState.displayType !== 'futures' ? futuresValue : indexValue;
    const newData = main.map(s => {
        const matchingData = sub.find(item => item.symbol === futuresDisctionary[s.symbol] + '-1');
        if (matchingData) {
            return {
                indexSignalr: s,
                futuresSignalr: matchingData,
            };
        }
        else {
            return {
                indexSignalr: s,
                futuresSignalr: s,
            };
        }
    });
    return (<styleds.container>
        <styleds.headerContainer>
          <StockIndexFuturesSymbol.header sortGroup={1}/>
          {/* <QuoteLayoutIcon /> */}
        </styleds.headerContainer>

        <styleds.listContainer>
          {symbolListArray.length === indexValue.length &&
            newData.map(symbol => {
                return (<styleds.listItem key={symbol.indexSignalr.symbol}>
                  <StockIndexFuturesSymbol.body indexSymbol={symbol.indexSignalr} futuresSymbol={symbol.futuresSignalr} strategyIndexConfig={daddy960_stocknerve_strategyGroup.main[1]} strategyFuturesConfig={daddy960_stocknerve_strategyGroup.main[0]}/>
                  <styleds.deleteButton onClick={() => acts.removeSymbol(symbol.indexSignalr.symbol || symbol.futuresSignalr.symbol)}>
                    <MdDeleteForever />
                  </styleds.deleteButton>
                </styleds.listItem>);
            })}
          {symbolListArray.length !== indexValue.length && <LoadCard.Loading />}
          {symbolListArray.length === 0 && <LoadCard.emtpty />}
        </styleds.listContainer>
      </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 36px 1fr;
    width: 100%;
    height: 100%;
    gap: 4px;
  `,
    headerContainer: styled.div `
    display: grid;
    grid-template-columns: 1fr 20px;
    width: 100%;
    height: 100%;
    //background-color: #363636;
    border-radius: 4px;
  `,
    listContainer: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 4px;
    flex-shrink: 0;
  `,
    listItem: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 1fr 20px;
    width: 100%;
    height: 64px;
  `,
    deleteButton: styled.div `
    ${fill_vertical_all_center};
    color: #aaaaaa;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      color: #cc3333;
      background-color: #232323;
    }
  `,
};
